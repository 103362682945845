.App {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
body {
	background-color: #202020;
}
h1 {
	color: #FFFFFF;
	text-align: center;
}

p {
	color: #FFFFFF;
	text-align: center;
}